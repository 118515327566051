#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff; /* Ensure this matches your page's background color */
}

.site-title h2 {
  margin: 0;
  font-size: 18px;
}

.site-title h2 a {
  color: #333;
  text-decoration: none;
}

.about-link a {
  text-decoration: none;
  font-size: 14px;
  color: #333; /* Make both links the same color */
}

.about-link a:hover {
  text-decoration: underline;
}

.site-title h2 a:hover {
  text-decoration: none; /* Ensure the title link does not get underlined */
}