.about-me-page {
  text-align: center;
  padding: 2em;
}

.container {
  max-width: 1200px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the container */
  padding: 1em; /* Optional: Add padding for better aesthetics */
}

.about-content {
  margin-top: 6em;
  display: flex;
  flex-direction: row; /* Default to row for larger screens */
  align-items: center;
  justify-content: center;
  gap: 2em; /* Adds space between text and photo */
}

.about-text {
  max-width: 60%;
  text-align: left;
}

.about-photo-wrapper {
  flex-shrink: 0; /* Prevents the photo from shrinking */
}

.about-photo {
  width: 300px; /* Make the photo larger */
  height: auto;
  border-radius: 50%;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column; /* Stack elements vertically on small screens */
  }

  .about-text {
    max-width: 100%;
    text-align: center; /* Center align text for better appearance on mobile */
  }

  .about-photo {
    width: 200px; /* Reduce photo size on small screens */
  }
}

@media (max-width: 480px) {
  .about-photo {
    width: 150px; /* Further reduce photo size on very small screens */
  }
}