.home-page {
  text-align: center;
  padding: 2em;
}

.my-masonry-grid {
  display: flex;
  margin-left: -15px; /* gutter size offset, decreased from 30px to 15px */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 5px; /* gutter size, decreased from 30px to 15px */
  background-clip: padding-box;
}

.image-grid {
  /* Remove the old image-grid styles, if any */
}

.image-tile {
  margin-bottom: 5px; /* decreased from 30px to 15px */
  display: inline-block;
  overflow: hidden;
}

.image-tile img {
  width: 100%;
  height: auto;
  transition: transform 0.3s;
}

.image-tile:hover img {
  transform: scale(1.05);
}

.about-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333;
  color: #fff;
  text-decoration: none;
}

.about-me-link {
  padding: 1em;
  font-size: 1.2em;
  text-align: center;
}