.image-tile {
  display: inline-block;
  margin: 5px;
  position: relative; /* Needed for absolute positioning of tooltip */
  overflow: hidden;
  opacity: 0; /* Initially hidden */
  transform: scale(0.95); /* Initially slightly zoomed out */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* Smooth transition */
}

.image-tile.visible {
  opacity: 1; /* Fully visible */
  transform: scale(1); /* Normal scale */
}

.image-tile img {
  width: 100%;
  height: auto;
  transition: transform 0.3s;
}

.image-tile:hover img {
  transform: scale(1.05);
}

.image-tooltip {
  position: absolute; /* Position relative to image-tile */
  bottom: 0; /* Align to the bottom */
  left: 0; /* Align to the left */
  width: 100%; /* Cover the full width of the image */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  color: #fff; /* White text */
  padding: 5px; /* Padding for some spacing */
  font-size: 10px; /* Adjust font size as needed */
  text-align: left;
  z-index: 10; /* Ensure tooltip is above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s; /* Smooth transition */
}

.image-tile:hover .image-tooltip {
  opacity: 1; /* Show the text when hovered */
}

/* Show the text on click */
.image-tile .image-tooltip.clicked {
  opacity: 1;
}

/* Visual feedback for double-click */
.click-again {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  color: white; /* White text */
  padding: 10px;
  border-radius: 5px;
  font-size: 12px; /* Adjust as needed */
  text-align: center;
  z-index: 20; /* Ensure it's above other elements */
  opacity: 0.85;
}

/* Hide the "click-again" message on non-touch devices */
@media (hover: hover) {
  .click-again {
    display: none;
  }
}